import React from "react";
import { GridColumnHeaderTitle, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { IconButton } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { useExpandCollapseAll } from "../useExpandCollapseAll";

export type GridDetailPanelHeaderProps = GridColumnHeaderParams & {
  collapseAllInactiveTitle?: string;
};

export function GridDetailPanelHeader({ colDef }: GridDetailPanelHeaderProps) {
  const { expandedRowIds, collapseAll } = useExpandCollapseAll();

  if (expandedRowIds.length < 2) {
    return (
      colDef.headerName && (
        <GridColumnHeaderTitle label={colDef.headerName} columnWidth={colDef.computedWidth} />
      )
    );
  }

  return <CollapseAllButton onClick={collapseAll} />;
}

function CollapseAllButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton
      className="grid-detail-panel-collapse-all"
      data-testid="grid-detail-panel-collapse-all"
      tabIndex={-1}
      aria-label="Collapse All"
      onClick={onClick}
    >
      <Icon name="VerticalAlignCenterOutlined" size="m" />
    </IconButton>
  );
}
