import React, { ForwardedRef } from "react";
import { cva } from "class-variance-authority";
import { Icon, IconProps } from "@ds-ui/icons";
import { Button, ButtonProps } from "@ds-ui/molecules/Button";

const iconButtonStyles = cva("", {
  variants: {
    size: {
      xs: "w-xl h-xl",
      s: "w-2xl h-2xl",
      m: "w-[28px] h-[28px]",
      l: "w-4xl h-4xl",
    },
  },
});

export type IconButtonProps = Omit<
  ButtonProps,
  "afterIcon" | "beforeIcon" | "loadingPlaceholder"
> & {
  "iconName": IconProps["name"];
  "aria-label": string;
};

export const IconButton = React.forwardRef(function IconButton(
  { iconName, size = "l", ...props }: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button size={size} {...props} className={iconButtonStyles({ size })} ref={ref}>
      <Icon name={iconName} size={size} />
    </Button>
  );
});
