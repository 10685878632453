import React from "react";
import { Icon } from "@ds-ui";
import { Button } from "ui";

export function CopyConfigButton({ onClick }: { onClick: () => void }) {
  return (
    <Button onClick={onClick} color="inherit">
      <Icon name="Terminal" color="action" />
    </Button>
  );
}
