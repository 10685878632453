import { cva } from "class-variance-authority";
import { focusedInteractiveOutlineStyles } from "@ds-ui/styles";

export const ButtonStyles = cva(
  "inline-flex rounded-xs border-0 normal-case min-w-auto cursor-pointer " +
    focusedInteractiveOutlineStyles,
  {
    variants: {
      fullWidth: {
        true: "w-full",
      },
      weight: {
        primary: "Button_ds_primary",
        secondary: "Button_ds_secondary",
        tertiary: "Button_ds_tertiary bg-transparent",
      },
      tone: {
        positive: "",
        destructive: "",
      },
      size: {
        l: "px-m py-s text-l leading-l gap-s",
        m: "px-s py-xs text-m leading-m gap-s",
        s: "px-s py-xs text-s leading-s gap-xs",
        xs: "p-xs text-xs leading-xs gap-xxs",
      },
      disabled: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      // Inactive styles
      {
        weight: "primary",
        tone: "positive",
        disabled: false,
        class: [
          "text-text-inverted",
          "bg-interactive-positive",
          "hover:bg-interactive-positive-highlight",
          "aria-pressed:bg-interactive-positive-highlight",
        ],
      },
      {
        weight: "secondary",
        tone: "positive",
        disabled: false,
        class: [
          "bg-background-idle",
          "hover:bg-background-active",
          "focus:bg-background-active",
          "aria-pressed:bg-background-active",
          "text-interactive-positive",
          "hover:text-interactive-positive",
          "focus:text-interactive-positive",
          "aria-pressed:text-interactive-positive",
        ],
      },
      {
        weight: "tertiary",
        tone: "positive",
        disabled: false,
        class: [
          "text-interactive-positive",
          "hover:text-interactive-positive",
          "focus:text-interactive-positive",
          "aria-pressed:text-interactive-positive",
          "hover:bg-background-active",
          "focus:bg-background-active",
          "aria-pressed:bg-background-active",
        ],
      },

      {
        weight: "primary",
        tone: "destructive",
        disabled: false,
        class: [
          "text-background-base0",
          "bg-interactive-destructive",
          "hover:bg-interactive-destructive-highlight",
          "focus:bg-interactive-destructive-highlight",
          "aria-pressed:bg-interactive-destructive-highlight",
        ],
      },
      {
        weight: "secondary",
        tone: "destructive",
        disabled: false,
        class: [
          "bg-background-destructive-idle",
          "hover:bg-background-destructive-active",
          "focus:bg-background-destructive-active",
          "aria-pressed:bg-background-destructive-active",
          "text-interactive-destructive",
          "hover:text-interactive-destructive-highlight",
          "focus:text-interactive-destructive-highlight",
          "aria-pressed:text-interactive-destructive-highlight",
        ],
      },
      {
        weight: "tertiary",
        tone: "destructive",
        disabled: false,
        class: [
          "text-interactive-destructive",
          "hover:text-interactive-destructive-highlight",
          "focus:text-interactive-destructive-highlight",
          "aria-pressed:text-interactive-destructive-highlight",
          "hover:bg-background-destructive-active",
          "focus:bg-background-destructive-active",
          "aria-pressed:bg-background-destructive-active",
        ],
      },
      {
        weight: "tertiary",
        tone: "destructive",
        disabled: false,
        class: [
          "text-interactive-destructive",
          "hover:text-interactive-destructive-highlight",
          "focus:text-interactive-destructive-highlight",
          "aria-pressed:text-interactive-destructive-highlight",
          "hover:bg-background-destructive-active",
          "focus:bg-background-destructive-active",
          "aria-pressed:bg-background-destructive-active",
        ],
      },
      // Disabled states
      {
        disabled: true,
        weight: "primary",
        class: [
          "text-background-base0",
          "bg-interactive-disabled",
          "hover:bg-interactive-disabled",
          "focus:bg-interactive-disabled",
        ],
      },
      {
        disabled: true,
        weight: "secondary",
        class: [
          "text-interactive-disabled",
          "bg-transparent",
          "border-interactive-disabled",
          "shadow-[inset_0_0_0_2px_var(--outlines-2)]",
        ],
      },
      {
        disabled: true,
        weight: "tertiary",
        class: ["text-interactive-disabled"],
      },
    ],
    defaultVariants: {
      weight: "primary",
      size: "l",
      tone: "positive",
      disabled: false,
      fullWidth: false,
    },
  }
);
