import constate from "constate";
import { useMemo } from "react";
import { ENV } from "runenv";
import { useHistoryParam } from "shared/useHistoryParam";
import { useLocationEditFilters } from "shared/editFiltersHooks";
import { datasetDefaults, policyDefaults } from "shared/dashboard";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import subDays from "date-fns/subDays";
import { omit } from "lodash";
import { types_SideSelectorEnum, types } from "api/gen";
import { useFilters as useFiltersState } from "modules/table";
import { useTimezoneState } from "modules/timezone";
import {
  addLocalTZOffset,
  removeLocalTZOffset,
  shiftDateByTimezoneOffset,
} from "libs/timezone/timezoneUtils";
import { AllCategories, AllDatasets, uncategorizedCategory } from "modules/risks/shared";
import { Sensitivity } from "ui/types";
import { UserFilter } from "modules/page-navigation";
import {
  applyEventIdsToGlobalFilter,
  applyLocationToGlobalFilter,
  applyActivityTypesToGlobalFilter,
  toQuery,
} from "./utils";
import {
  Severity,
  isAllSeveritiesSelected,
  isNullSeverityAllowed,
} from "../dashboard-core/severity";
import {
  isAllSensitivitiesSelected,
  isNullSensitivityAllowed,
} from "../dashboard-core/sensitivity";
import { ActivityTypeFilterOption, ExpandableLocation } from "./types";
import { makeInitialStateForExpansions } from "./services/makeInitianStateForLocations";

const getFiltersConfig = ({
  defaultCategoryIds,
  timezone,
}: {
  defaultCategoryIds: string[];
  timezone: string;
}) => ({
  querySide: {
    queryKey: "query-side",
    defaultValue: types_SideSelectorEnum.SideSelectorCategory as types.SideSelector,
  },
  // Exact UTC date which is sent to an API
  timeFilter: {
    queryKey: "time-filter",
    defaultValue: (() => {
      const todayInUTC = addLocalTZOffset(new Date());
      const todayInUserTimezone = shiftDateByTimezoneOffset(todayInUTC, timezone);

      const startTime = ENV.START_DATE
        ? new Date(ENV.START_DATE)
        : removeLocalTZOffset(
            startOfDay(subDays(todayInUserTimezone, ENV.FEATURES.RO_DEFAULT_DAYS_BACK))
          );
      const endTime = ENV.END_DATE
        ? new Date(ENV.END_DATE)
        : removeLocalTZOffset(endOfDay(todayInUTC));

      return {
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
      };
    })(),
  },
  globalFilter: {
    queryKey: "global-filter",
    defaultValue: "" as string,
  },
  nlpSearchQuery: {
    queryKey: "nlp-query",
    defaultValue: "" as string,
  },
  selectedCategories: {
    queryKey: "selectedCategories",
    defaultValue: defaultCategoryIds,
  },
  selectedDatasets: {
    queryKey: "selectedDatasets",
    defaultValue: [AllDatasets] as string[],
  },
  selectedSeverities: {
    queryKey: "selectedSeverities",
    defaultValue: [] as Severity[],
  },
  selectedSensitivities: {
    queryKey: "selectedSensitivities",
    defaultValue: [] as Sensitivity[],
  },
  selectedUsers: {
    queryKey: "selectedUsers",
    defaultValue: new Set([]) as Set<{
      user: string;
      count: number;
    }>,
  },
  selectedDirectoryUsers: {
    queryKey: "selectedDirectoryUsers",
    defaultValue: [] as UserFilter[],
  },
  selectedLocations: {
    queryKey: "selectedLocations",
    defaultValue: new Set<types.ExpandableLocationItem>([]),
  },
  selectedLocationsDestinations: {
    queryKey: "selectedLocationsDestinations",
    defaultValue: new Set<types.ExpandableLocationItem>([]),
  },
  selectedLocationsDestinationsGlobal: {
    queryKey: "selectedLocationsDestinationsGlobal",
    defaultValue: new Set<types.ExpandableLocationItem>([]),
  },
  selectedLocationsSourcesGlobal: {
    queryKey: "selectedLocationsSourcesGlobal",
    defaultValue: new Set<types.ExpandableLocationItem>([]),
  },
  selectedLocationsGlobal: {
    queryKey: "selectedLocationsGlobal",
    defaultValue: new Set<types.ExpandableLocationItem>([]),
  },
  selectedLocationsSources: {
    queryKey: "selectedLocationsSources",
    defaultValue: new Set<types.ExpandableLocationItem>([]),
  },
  selectedHostnames: {
    queryKey: "selectedHostnames",
    defaultValue: new Set<string>(),
  },
  selectedEventIds: {
    queryKey: "selectedEventIds",
    defaultValue: [] as string[],
  },
  selectedActivityTypes: {
    queryKey: "selectedActivityTypes",
    defaultValue: [] as ActivityTypeFilterOption[],
  },
});

export function useFiltersInternal({
  categories,
  datasets,
  initialEventIds,
  initialTimeRange,
}: {
  categories: types.Category[];
  datasets: types.Dataset[];
  initialEventIds?: string[];
  initialTimeRange?: types.TimesFilter | null;
}) {
  const defaultCategoryIds = categories.map((c) => c.id!) ?? [];
  const { timezone } = useTimezoneState();

  let {
    timeFilter,
    setTimeFilter,
    globalFilter: initialGlobalFilter,
    setGlobalFilter,
    selectedDatasets,
    selectedCategories,
    setSelectedDatasets: setSelectedDatasetsInternal,
    setSelectedCategories: setSelectedCategoriesInternal,
    querySide: querySideState,
    setQuerySide,
    selectedLocations,
    selectedLocationsDestinationsGlobal,
    selectedLocationsSourcesGlobal,
    selectedLocationsGlobal,
    setSelectedLocations,
    selectedLocationsDestinations,
    setSelectedLocationsDestinations,
    selectedLocationsSources,
    setSelectedLocationsSources,
    selectedSeverities,
    selectedSensitivities,
    setSelectedSeverities,
    setSelectedSensitivities,
    selectedActivityTypes,
    setSelectedActivityTypes,
    resetSelectedCategories: resetSelectedCategoriesInternal,
    ...props
  } = useFiltersState({
    filters: getFiltersConfig({ defaultCategoryIds, timezone }),
    initialValue: {
      selectedEventIds: initialEventIds,
      selectedCategories: initialEventIds?.length ? [AllCategories] : defaultCategoryIds,
      timeFilter: initialTimeRange,
      selectedActivityTypes: [
        ActivityTypeFilterOption.DataActivity,
        ActivityTypeFilterOption.ScanActivity,
      ],
    },
    clearAllFilter: (name: string) => {
      return name !== "timeFilter";
    },
  });

  let globalFilter = applyLocationToGlobalFilter(initialGlobalFilter, {
    selectedLocationsDestinationsGlobal,
    selectedLocationsGlobal,
    selectedLocationsSourcesGlobal,
  });

  const querySide = (
    Array.isArray(querySideState) ? querySideState[0] : querySideState
  ) as types.SideSelector;

  const [expandedLocations, setExpandedLocations] = useHistoryParam<ExpandableLocation[]>(
    "expandedLocations",
    ENV.FEATURES.LOCATIONS_WIDGET_V1_5 ? makeInitialStateForExpansions() : []
  );
  function setSelectedDatasets(v: any) {
    let newValue = v;
    if (v.length === 0) {
      newValue = [AllDatasets];
    }
    setSelectedDatasetsInternal(newValue);
  }
  function setSelectedCategories(v: any) {
    let newValue = v.filter((el: any) => Boolean(el));
    if (v.length === 0) {
      newValue = defaultCategoryIds;
    }
    setSelectedCategoriesInternal(newValue);
  }
  const editFilters = useLocationEditFilters();

  const originalSetDataset = editFilters.datasetEdit.startEditing;
  editFilters.datasetEdit.startEditing = (
    id,
    dataset = datasetDefaults as any,
    viewMode?: boolean
  ) => {
    if (id === "search") {
      dataset = { ...dataset, category_ids: categories.map((el) => el.id!) };
    }
    originalSetDataset(id, dataset, viewMode);
  };
  const originalSetPolicy = editFilters.policyEdit.startEditingPolicy;
  editFilters.policyEdit.startEditingPolicy = (
    id,
    category = policyDefaults,
    viewMode?: boolean
  ) => {
    if (id === "search") {
      category = {
        ...category,
        dataset_ids: datasets.map((el) => el.id!),
        dataset_sensitivities: category.dataset_sensitivities || [],
      };
    }
    originalSetPolicy(id, category, viewMode);
  };
  if (editFilters.policyEdit.isEditing) {
    selectedCategories = [AllCategories];
  }
  if (editFilters.datasetEdit.isEditing) {
    selectedDatasets = [AllDatasets];
  }
  globalFilter = applyEventIdsToGlobalFilter(globalFilter, props.selectedEventIds);

  if (ENV.FEATURES.DAR_SCAN) {
    globalFilter = applyActivityTypesToGlobalFilter(globalFilter, selectedActivityTypes);
  }

  const allSeverities = isAllSeveritiesSelected(selectedSeverities);
  const allSensitivities = isAllSensitivitiesSelected(selectedSensitivities);
  const nullSeverityAllowed = isNullSeverityAllowed(selectedSeverities);
  const nullSensitivityAllowed = isNullSensitivityAllowed(selectedSensitivities);
  const isAllPoliciesSelected = selectedCategories.length >= categories.length;
  const resetSelectedSeverities = () => {
    props.resetSelectedSeverities();
  };

  const resetSelectedCategories = () => {
    resetSelectedCategoriesInternal();
  };
  const apiQuery = useMemo(() => {
    const previewCategory = editFilters.policyEdit?.editingPolicyValues
      ? { ...editFilters.policyEdit.editingPolicyValues }
      : editFilters.policyEdit?.editingPolicyValues;
    const isSearchPolicy = editFilters.policyEdit.isSearch;

    // we don't want to send severity inside search cause user can't change it
    if (isSearchPolicy) {
      delete previewCategory["severity"];
    }
    const selectedCategoriesFinal = [...selectedCategories];
    if (nullSeverityAllowed) {
      selectedCategoriesFinal.push(uncategorizedCategory);
    }
    return toQuery({
      timeFilter,
      globalFilter,
      selectedCategories: selectedCategoriesFinal,
      selectedDatasets,
      selectedUsers: props.selectedUsers,
      selectedDirectoryUsers: props.selectedDirectoryUsers,
      selectedLocations: selectedLocations,
      selectedLocationsDestinations,
      selectedLocationsSources,
      selectedSeverities,
      selectedSensitivities,
      isAllSeveritiesSelected: allSeverities,
      isAllPoliciesSelected,
      previewDataset: editFilters.datasetEdit.editingValues,
      previewCategory,
      isBothSearch: editFilters.policyEdit.isSearch && editFilters.datasetEdit.isSearch,
      isAppliedToCategoriesAreHidden:
        editFilters.policyEdit.isSearch && editFilters.datasetEdit.isEditing,
      selectedHostnames: props.selectedHostnames,
      selectedLocationsDestinationsGlobal,
      selectedLocationsSourcesGlobal,
      selectedLocationsGlobal,
      selectedActivityTypes,
    });
  }, [
    timeFilter,
    selectedCategories,
    selectedDatasets,
    props.selectedDirectoryUsers,
    props.selectedUsers,
    selectedLocations,
    editFilters.datasetEdit.editingValues,
    editFilters.policyEdit.editingPolicyValues,
    querySide,
    props.selectedHostnames,
    selectedLocationsDestinationsGlobal,
    selectedLocationsSourcesGlobal,
    selectedLocationsGlobal,
  ]);

  const selectedLocationsSetter: Record<
    types.SideSelector,
    {
      selectedLocations: typeof selectedLocations;
      setSelectedLocations: typeof setSelectedLocations;
    }
  > = {
    [types_SideSelectorEnum.SideSelectorCategory]: {
      selectedLocations: selectedLocationsDestinations,
      setSelectedLocations: setSelectedLocationsDestinations,
    },
    [types_SideSelectorEnum.SideSelectorDataset]: {
      selectedLocations: selectedLocationsSources,
      setSelectedLocations: setSelectedLocationsSources,
    },
    [types_SideSelectorEnum.SideSelectorBoth]: {
      selectedLocations: selectedLocations,
      setSelectedLocations: setSelectedLocations,
    },
  };

  return {
    // Don't use spreads for widgets, explicitly pass props
    userWidgetState: {
      selectedUsers: props.selectedUsers,
      setSelectedUsers: props.setSelectedUsers,
      apiQuery: omit(apiQuery, "users_filter"),
    },
    locationsWidgetState: {
      querySide,
      setQuerySide,
      expandedLocations,
      setExpandedLocations,
      ...selectedLocationsSetter[querySide],
      apiQuery,
    },
    eventsWidget: {
      apiQuery,
      selectedCategories,
    },
    topFiltersWidget: {
      dateFilters: timeFilter,
      setDateFilters: setTimeFilter,
    },
    listsWidget: {
      apiQuery,
      datasetEdit: editFilters.datasetEdit,
      policyEdit: editFilters.policyEdit,
      selectedCategories,
      selectedDatasets,
      allDatasets: selectedDatasets?.includes(AllDatasets),
      allCategories: isAllPoliciesSelected,
    },
    dataflowsWidget: {
      isNullSeverityAllowed: nullSeverityAllowed,
      apiQuery,
      selectedDataset: selectedDatasets?.[0] || AllDatasets,
      setSelectedDatasets,
      setSelectedDataset: (v: any) => setSelectedDatasets([v]),
      allCategories: isAllPoliciesSelected,
      selectedCategories,
      setSelectedCategories,
      setSelectedCategory: (v: any) => setSelectedCategories([v]),
      resetSelectedCategories,
      selectedSeverities,
      setSelectedSeverities,
      allSeverities,
      selectedSensitivities,
      datasetEdit: editFilters.datasetEdit,
      policyEdit: editFilters.policyEdit,
    },
    bottomFiltersWidget: {
      getChipsFilterProps: () => {
        const { filters, ...restParams } = props.getChipsFilterProps();
        return {
          filters: {
            ...filters,
            selectedDatasets: {
              ...filters.selectedDatasets,
              isZero:
                filters.selectedDatasets.isZero ||
                (selectedDatasets.length === 1 && selectedDatasets[0] === AllDatasets),
            },
            selectedSeverities: {
              ...filters.selectedSeverities,
              reset: resetSelectedSeverities,
            },
            selectedCategories: {
              ...filters.selectedCategories,
              reset: resetSelectedCategories,
            },
          },
          ...restParams,
        };
      },
      policyEdit: editFilters.policyEdit,
      selectedSeverities,
      datasetEdit: editFilters.datasetEdit,
      allCategories: isAllPoliciesSelected,
      allSeverities,
      allSensitivities,
      allSensitivitiesWithNull: allSensitivities && nullSensitivityAllowed,
      selectedSensitivities,
      setSelectedSeverities,
      setSelectedSensitivities,
      allSeveritiesWithNull: allSeverities && nullSeverityAllowed,
      selectedActivityTypes,
      setSelectedActivityTypes,
    },
    apiFilters: {
      selectedHostnames: props.selectedHostnames,
      globalFilter,
      policyEdit: editFilters.policyEdit,
      datasetEdit: editFilters.datasetEdit,
      expandedLocations,
      selectedCategories: selectedCategories,
      selectedDatasets,
      selectedSeverities,
      selectedActivityTypes,
      apiQuery,
    },
  };
}

export const [
  FiltersProvider,
  useUserWidgetState,
  useLocationsWidgetState,
  useEventsWidgetState,
  useTopFiltersWidgetState,
  useListsWidgetState,
  useDataflowsWidgetState,
  useBottomFiltersWidgetState,
  useApiFiltersState,
] = constate(
  useFiltersInternal,
  (state) => state.userWidgetState,
  (state) => state.locationsWidgetState,
  (state) => state.eventsWidget,
  (state) => state.topFiltersWidget,
  (state) => state.listsWidget,
  (state) => state.dataflowsWidget,
  (state) => state.bottomFiltersWidget,
  (state) => state.apiFilters
);
