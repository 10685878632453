import React, { useId } from "react";
import { PopoverProps } from "@mui/material";
import { usePopover } from "libs/hooks";
import { Menu } from "./Menu";
import { MenuItemProps } from "./MenuItem";

export type ActionsPopoverMenuProps = {
  popoverProps?: Partial<PopoverProps>;
  width?: number;
  children?: Array<React.ReactElement<MenuItemProps> | null>;
  renderTrigger: (
    props: ReturnType<typeof useTriggerAriaProps> & {
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    },
    isOpen: boolean
  ) => React.ReactElement;
};

export function ActionsPopoverMenu({ children, renderTrigger }: ActionsPopoverMenuProps) {
  const menuId = useId();

  const [menu, actions] = usePopover((popoverApi) => (
    <Menu id={menuId} {...popoverApi}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          // TODO: Check if these props are still used or not
          // isPopoverOpened: popoverApi.open,
          // onPopoverClose: popoverApi.onClose,
          onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            const props = child.props;
            props.onClick?.(e);

            if (props.closeOnClick) {
              actions.onClose();
            }
          },
        });
      })}
    </Menu>
  ));

  const triggerAriaProps = useTriggerAriaProps(menuId, actions.open);

  const handleClick = (e: React.MouseEvent) => {
    e?.stopPropagation?.();
    actions.onOpen(e.currentTarget);
  };

  return (
    <>
      {renderTrigger({ onClick: handleClick, ...triggerAriaProps }, actions.open)}
      {menu}
    </>
  );
}

function useTriggerAriaProps(menuId: string, isOpen: boolean) {
  return {
    "aria-haspopup": "true",
    "aria-controls": isOpen ? menuId : undefined,
    "aria-expanded": isOpen ? "true" : undefined,
  } as const;
}
