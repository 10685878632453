import React, { ForwardedRef, forwardRef } from "react";
import { TextFieldProps, TextField, TextFieldVariants } from "@mui/material";

export type InputSelectProps<Variant extends TextFieldVariants = TextFieldVariants> = Omit<
  TextFieldProps<Variant>,
  "select"
>;

export const InputSelect = forwardRef(function InputSelect<Variant extends TextFieldVariants>(
  props: InputSelectProps<Variant>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return <TextField ref={ref} select {...props} />;
});
