export function recordKeys<R extends object>(record: R): Array<keyof R> {
  return Object.keys(record) as Array<keyof R>;
}

export function recordValues<R extends object>(record: R): Array<R[keyof R]> {
  return Object.values(record) as Array<R[keyof R]>;
}

export function recordEntries<R extends object>(record: R): Array<[keyof R, R[keyof R]]> {
  return Object.entries(record) as Array<[keyof R, R[keyof R]]>;
}

/* Generic type guards */
export function isObject(value: unknown): value is object {
  return value !== null && typeof value === "object";
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}
function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isPositiveNumber(value: unknown): value is number {
  return isNumber(value) && value > 0;
}

export function isNegativeNumber(value: unknown): value is number {
  return isNumber(value) && value < 0;
}

/**
 * Type guard to check that a value is defined
 * @param value
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

/**
 *
 * @example
 *  const safeVal = assertNonNullish(val, 'val is not defined');
 *
 * Type guard to check that a value is defined or not, throws error otherwise
 * @param value - any value
 * @param message - custom error message
 * @returns value - if the value is defined
 * @throws Error  - if the value is not defined
 */
export const assertNonNullish: <T>(value?: T | null, message?: string) => T = (value, message) => {
  if (value === undefined || value === null) {
    const errorMessage = message || `Failed assert for ${value}`;
    throw new Error(errorMessage);
  }

  return value;
};
