import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { Icon } from "@ds-ui/icons";
import { Modal as ModalMui, ModalProps as ModalPropsMui } from "@mui/material";
import { Text } from "../Text";
import { Button } from "../Button";

const modalStyles = cva(
  "rounded-lg outline-none max-h-[90vh] p-3xl bg-background-base2 shadow-sm relative left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col",
  {
    variants: {
      fullWidth: {
        true: "flex-grow",
        false: "",
      },
      maxWidth: {
        xl: "!max-w-[1200px]",
        l: "!max-w-[920px]",
        m: "!max-w-[720px]",
        s: "!max-w-[520px]",
      },
    },
    defaultVariants: { fullWidth: true, maxWidth: "l" },
  }
);

export type ModalProps = Partial<VariantProps<typeof modalStyles>> &
  Omit<ModalPropsMui, "title"> & {
    title?: React.ReactNode;
  };

export const Modal = ({
  open,
  children,
  title,
  fullWidth,
  maxWidth,
  onClose,
  ...props
}: ModalProps) => {
  return (
    <ModalMui
      onClose={onClose}
      open={open}
      {...props}
      slotProps={{ backdrop: { className: "bg-backdrops-1" } }}
    >
      <div className={modalStyles({ fullWidth, maxWidth })}>
        {onClose && (
          <Button
            onClick={(e) => onClose(e, "backdropClick")}
            size="xs"
            tone="positive"
            className="absolute mt-3xl mr-3xl"
            style={{ right: 0, top: 0 }}
            weight="tertiary"
            testId="closeModal"
          >
            <Icon size="l" name="CloseOutlined" />
          </Button>
        )}

        {title && (
          <Text as="div" className="mb-2xl" variant="secondaryTitle" color="primary">
            {title}
          </Text>
        )}

        <div className="flex flex-col overflow-auto flex-grow"> {children}</div>
      </div>
    </ModalMui>
  );
};
