import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { TextField, Checkbox, FormControlLabel, Radio } from "@mui/material";
import { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { CheckboxProps } from "@mui/material/Checkbox";
import { mapErrorOnly, mapProps } from "ui";

export function TextFieldComponent(props: any) {
  return <TextField fullWidth {...mapProps(props)} />;
}

export const TextFieldComponentV2 = React.forwardRef(function TextFieldComponentV2(
  { input, meta, hasHelperText, ...props }: any,
  ref
) {
  return (
    <TextField
      ref={ref}
      fullWidth
      {...props}
      {...input}
      {...mapErrorOnly({ meta, hasHelperText })}
    />
  );
});

type CheckboxComponentProps = FieldRenderProps &
  CheckboxProps & {
    label?: string;
    labelProps?: Partial<FormControlLabelProps>;
    type?: string;
  };
export const CheckboxComponent = ({ label, labelProps, ...props }: CheckboxComponentProps) => {
  const { type: _, ...mprops } = mapProps(props);
  if (label) {
    return <FormControlLabel {...labelProps} control={<Checkbox {...mprops} />} label={label} />;
  }
  return <Checkbox {...mprops} />;
};

export function CheckboxField({ name, ...props }: FieldProps) {
  return <Field name={name} component={CheckboxComponent} type="checkbox" {...props} />;
}

export const RadioComponent = (props: FieldRenderProps) => {
  const {
    input: { checked, value, name, onChange, ...restInput },
  } = props;

  return (
    <Radio name={name} inputProps={restInput} onChange={onChange} checked={checked} value={value} />
  );
};

export function RadioField(props: FieldProps) {
  return <Field {...props} type="radio" component={RadioComponent} />;
}
