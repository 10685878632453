import { LS_COLOR_THEME } from "@shared/color-theme/useTheme";
import { lightTheme, darkTheme, EnhancedTheme, AvailableTheme } from "./variants";

/*
 * TODO: Move the all theme related logic here
 * - Provider
 * - Separate theme selection from the user preferences logic
 * - theme toggle component
 *
 * */

export const getTheme = (theme?: AvailableTheme) => {
  if (theme) {
    return theme === "light" ? lightTheme : darkTheme;
  }

  try {
    const storedTheme = localStorage.getItem(LS_COLOR_THEME);
    const colorTheme = storedTheme ? JSON.parse(storedTheme) : null;

    if (colorTheme === "light") {
      return lightTheme;
    }
    if (colorTheme === "dark") {
      return darkTheme;
    }

    return window.matchMedia?.("(prefers-color-scheme: dark)").matches ? darkTheme : lightTheme;
  } catch (error) {
    console.error("Error parsing theme from localStorage:", error);
    return lightTheme; // Fallback to light theme in case of error
  }
};

// Export theme as a dynamic getter
export const theme = new Proxy(
  {},
  {
    get: (_, prop) => {
      if (prop === Symbol.toPrimitive || prop === "valueOf") {
        return getTheme;
      }
      if (
        prop === "toJSON" ||
        prop === "inspect" ||
        prop === Symbol.for("nodejs.util.inspect.custom")
      ) {
        return () => getTheme();
      }
      if (prop === "then") {
        return undefined;
      } // Prevents Promise-like behavior in async contexts
      if (prop === "toString") {
        return () => JSON.stringify(getTheme(), null, 2);
      }
      return getTheme()[prop as keyof EnhancedTheme];
    },
    apply: () => getTheme(),
    ownKeys: () => Reflect.ownKeys(getTheme()),
    getOwnPropertyDescriptor: (_, prop) => ({
      ...Object.getOwnPropertyDescriptor(getTheme(), prop),
      configurable: true,
      enumerable: true,
    }),
  }
) as EnhancedTheme;
