import React, { forwardRef, useMemo, useState } from "react";
import { debounce } from "lodash";
import { HighlightedTextComponent } from "shared/HighlightedTextComponent";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import { Autocomplete, CircularProgress, TextField, TextFieldProps } from "@mui/material";
import { Link, space, VStack } from "ui";
import { GetFilterSuggestionsFn } from "../types";
import { useGridFilterSuggestions } from "../useGridFilterSuggestions";

const filterOptionsQueryKey = "filter-options";

export function GridFilterAutocomplete({
  item,
  applyValue,
  getFilterSuggestions,
}: GridFilterInputValueProps & { getFilterSuggestions?: GetFilterSuggestionsFn }) {
  const value = useMemo(
    () => (item.value ? (Array.isArray(item.value) ? item.value : [item.value]) : []),
    [item.value]
  );
  const [inputValue, setInputValue] = useState("");
  const debouncedInputValueUpdate = useMemo(() => debounce(setInputValue, 500), [setInputValue]);
  const [wasOpened, setWasOpened] = useState(false);

  const {
    data: options,
    isFetching,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
  } = useGridFilterSuggestions({
    key: [filterOptionsQueryKey, { field: item.field, search: inputValue }],
    fetchSuggestions: (nextPage) =>
      getFilterSuggestions!({ pageSize: 50, search: inputValue, nextPage }),
    enabled: wasOpened && Boolean(getFilterSuggestions),
  });

  return (
    <Autocomplete
      freeSolo
      fullWidth
      multiple
      autoComplete
      loading={isFetching}
      options={options || []}
      filterOptions={(x) => x}
      value={value}
      onChange={(_, value) => applyValue({ ...item, value: value.length ? value : undefined })}
      onInputChange={(_, value) => debouncedInputValueUpdate(value)}
      renderInput={(params) => <AutoCompleteInput isLoading={isFetching} {...params} />}
      renderOption={(props, option, { inputValue }) => {
        return (
          <li {...props}>
            <HighlightedTextComponent title={option} highlightSubstring={inputValue} />
          </li>
        );
      }}
      onOpen={() => setWasOpened(true)}
      onClose={() => debouncedInputValueUpdate.cancel()}
      ListboxComponent={ListboxComponent}
      ListboxProps={{ canFetchMore, fetchMore } as any}
    />
  );
}

const ListboxComponent = forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLElement> & { canFetchMore?: boolean; fetchMore?: () => any }
>(function ListboxComponent({ children, canFetchMore, fetchMore, ...props }, ref) {
  return (
    <ul {...props} ref={ref}>
      {children}
      {canFetchMore && (
        <VStack align="center">
          <Link sx={{ marginTop: space[2] }} onClick={() => fetchMore?.()}>
            Load more
          </Link>
        </VStack>
      )}
    </ul>
  );
});

function AutoCompleteInput({ isLoading, ...props }: TextFieldProps & { isLoading?: boolean }) {
  return (
    <TextField
      {...props}
      label="Value"
      InputProps={{
        ...props.InputProps,
        style: { marginTop: "21px", minHeight: "33px" },
        endAdornment: (
          <React.Fragment>
            {isLoading ? <CircularProgress color="primary" size={18} /> : null}
            {props.InputProps?.endAdornment}
          </React.Fragment>
        ),
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        style: { transform: "translate(0, 1.5px)" },
      }}
    />
  );
}
