import { alpha, Theme } from "@ds-proxy";
import { generateColorGroup } from "./utils";

// TODO: Review these custom properties and ideally deprecate them

const dataVizColors = [
  "#722FAE",
  "#326CC2",
  "#009BC1",
  "#008562",
  "#E9B600",
  "#D77B51",
  "#713636",
  "#EB5FA4",
];
const chordDiagramColors = ["#4C9BD0", "#B367D9", "#F261A1", "#FDDB63", "#FDDB63", "#FDDB63"];

/*
 * Use the theme prop if we need to access the theme object (we shouldn't need to)
 */
export const getCustomProperties = (_: Theme) => {
  return {
    radii: {
      xs: "2px",
      sm: "4px",
      md: "8px",
      lg: "16px",
      full: "9999px",
    },
    customColors: {
      severity: {
        critical: generateColorGroup("#B91C1C"),
        high: generateColorGroup("#F87171"),
        medium: generateColorGroup("#D97706"),
        low: generateColorGroup("#FCD34D"),
        informational: generateColorGroup("#93C5FD"),
        null: generateColorGroup("#7C7C7C"),
      },
      sensitivity: {
        critical: generateColorGroup("#B91C1C"),
        high: generateColorGroup("#F87171"),
        moderate: generateColorGroup("#D97706"),
        low: generateColorGroup("#FCD34D"),
        unrestricted: generateColorGroup("#93C5FD"),
      },
      riskLevel: {
        "Critical": "#B91C1C",
        "High": "#F87171",
        "Medium": "#D97706",
        "Low": "#FCD34D",
        "Very Low": "#24A148", // TODO: discuss and update color
        "": "#BDBDBD", // TODO: Discuss and update color
      },
      panelBackground: {
        // TODO: Deprecate, use css vars
        idle: "#FFF",
        selectedColor: "#E6EAEE",
      },
      riskyColors: {
        "100": "#F4C03B",
        "200": "#F48C40",
        "300": "#D95946",
      },
      random: {
        grey2: "#FBFBFB",
        // ^ random colors
        light100: "#F1F3F4",
        light120: "#F7F7F7",
        light180: "#EAEDEF",
        // light200: "#E1E5E8",
        light300: "#C5CDD3",
        blue100: "#43A7EA",
        blue200: "#0078B7",
        blue300: "#046294",
        dark100: "#046294",
        dark300: "#000000",
        gray100: "#6B6B6B",
        blueLight100: "#E7F1F8",
        // from src/modules/risks/search/theme.tsx
        gray1: "hsl(220, 16%, 89%)",
        gray2: "hsl(220,21%,95%)",
        gray3: "hsl(0, 0%, 98%)",
        gray4: "hsl(222, 10%, 75%)",
        gray5: "hsl(220, 16%, 89%)",
        blue1: "hsl(204,80%,59%)",
      },
      // from src/modules/risks/shared/theme.tsx
      risks: {
        grey: "#CACACA",
        gray2: "#FBFBFB",
        backgroundGrey: "#f2f3f4",
        // green: "#5DCB6F",
        green: "var(--semantic-success-primary, #059669)",
        // red: "#BF3E3E",
        red: "var(--semantic-error-primary, #B91C1C)",
        blue1: "#C7E5F9",
        blue2: "#43A7EA",
        blue3: "#F8F9FB",
        dividerBlue2: "1px solid #43A7EA",
        white: "#ffffff",
        // barHover: "#007dd1",
        barHover: "var(--interactive-positive-highlight, #164E63)",
        menuItemHover: "#ECEEF1",
        yellow: "#F8A458",
        errorBorder: "#FF1212",
        // rulesBackground: "#f5f5f5",
        backgroundDark: "#525252",
        rulesHeader: "#5b6064",
        greyBg: "#616161",
        selectedColor: "#E6EAEE",
        // sidePanelBackground: "#f8f9fb",
        sidePanelBackground: "var(--background-base1)",
        // sidePanelBorder: "1px solid #eeeff3",
        sidePanelBorder: "1px solid #eeeff3",
        selectedResponseFilter: "rgba(143, 154, 165, 0.44)",
        risksColorMap: {
          low_risky: "#F8A458",
          risky: "var(--semantic-error-primary, #B91C1C)",
          approved: "var(--semantic-success-primary, #059669)",
          other: "#FBFBFB",
        },
      },
      // from src/modules/risks/dashboard-core/ai-assessment.tsx
      aiAssessment: {
        informational: "#60CAFA",
        low: "#F1C21B",
        medium: "#FF832B",
        high: "#DA1E28",
        critical: "#570000",
      },
      charts: {
        general: {},
        purple1: "#6c5efb",
        purple2: "#c998ff",
        purple3: "#a44afe",
        chartsColors: ["#84B2D0", "#16598E"],
        barHover: "#366482",
        barSelected: "#366482",
        bg: "#fff",
        text: "#616161",
        border: "#E4E4E4",
        // from src/modules/360/molecules/ChordDiagram.tsx
        chordDiagram: chordDiagramColors,
        radar: {
          dotStroke: "#8884d8",
          dotFill: "#FFF",
          dataColors: dataVizColors,
        },
      },
      tables: {
        row: {
          idle: "",
          hover: "",
          even: "#F3F3F3",
          odd: "transparent",
        },
      },
      userDirectoryState: {
        linked: "#82C363",
        unlinked: "#B0B7AA", // var(--interactive-disabled)
        deleting: "#f44336", // var(--semantic-error-primary)
        invalid: "",
        validating: "",
      },
      buttons: {
        icon: {
          idle: "#EFEFEF",
          hover: "#C5CDD3",
        },
      },
      gridColors: {
        searchIcon: "#323232",
        main: "var(--text-primary)",
        darkGrey: "rgba(0, 0, 0, 0.38)",
        primary: "#0891B2",
        primaryLight: alpha("#0891B2", 0.5),
        primaryBackground: alpha("#0891B2", 0.08),
        link: "#757575",
        highlight: "#C62828",
      },
      riskyUsersColors: {
        background: "#F2F4F8",
        secondary: "rgba(0, 0, 0, 0.6)",
        darkGrey: "rgba(0, 0, 0, 0.38)",
        grey: "rgba(0, 0, 0, 0.23)",
        lightGrey: "rgba(0, 0, 0, 0.1)",
        noData: alpha("#0891B2", 0.5),
        warning: "#F5DA3D",
        sidePanel: {
          body: "#00000099",
          button: "#9E9E9E",
        },
        userStatsPanel: {
          background: "#F8F9FB",
        },
      },

      endpointSensors: {
        yellow: "#F1C21B",
        disabledYellow: "#E8DDC0",
        red: "#DA1E28",
        disabledRed: "#b76f6f",
        green: "#24A148",
        grey: "#8E8E8E",
        cardShadow: "0px 5px 12px rgba(0, 0, 0, 0.1)",
      },
    },
    // TODO: use 8px grid from material with css vars
    customSpacing: {
      "-6": "-24px", // No direct Material equivalent
      "-5": "-20px", // No direct Material equivalent
      "-4": "-16px", // Material 16dp
      "-3": "-12px", // No direct Material equivalent
      "-2": "-8px", // Material 8dp
      "-1": "-4px", // No direct Material equivalent
      "0": "0px", // Baseline
      "1": "4px", // No direct Material equivalent
      "2": "8px", // Material 8dp
      "3": "12px", // No direct Material equivalent
      "4": "16px", // Material 16dp
      "5": "20px", // No direct Material equivalent
      "6": "24px", // Material 24dp
      "7": "28px", // No direct Material equivalent
      "8": "32px", // Material 32dp
      "9": "36px", // No direct Material equivalent
      "10": "40px", // Material 40dp
    },
    // TODO: Match these styles with the material ones
    customTypography: {
      header3: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "48px",
        lineHeight: "56px",
      },
      header4: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "32px",
        lineHeight: "37px",
      },
      header5M: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
      },
      header6MC: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "19px",
        textTransform: "uppercase",
      },
      subtitle2: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
      },
      subtitle3: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
      },
      subtitle4M: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
      },
      body2: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
      },
      body3: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "15px",
      },
      body4M: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "15px",
      },
      buttonMC: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        textTransform: "uppercase",
      },
      buttonM: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.005em",
      },
      button: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.005em",
      },
      caption: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "14px",
      },
    },
  };
};
