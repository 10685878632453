import { flatMap, get, isArray, isString } from "lodash";
import { isNullOrUndefined } from "libs/utils";

export type FilterConfig<EntryType> = Record<
  string,
  {
    queryKey: string;
    defaultValue: unknown;
    memoryFilterProps: InMemoryFilterProps<EntryType>;
  }
>;

type InMemoryFilterProps<EntryType> = {
  filterType: InMemoryFilterType;
  getter: ValueGetter<EntryType>;
};

export enum InMemoryFilterType {
  // Returns entries that are equal to the filter value
  Inclusion = "inclusion",

  // Used with multiple filter values. Returnes entries that are
  // equal to any of the filter values.
  UnionInclusion = "union-inclusion",

  // Used with date values. Returns entries that are within the
  // date range defined by the filter
  DateRange = "date-range",

  // Returns entries that contain the filter value as a substring
  String = "string",
}

/**
 * Type that describes either a JS object key or a path to a nested key separated by dots
 */
type ValueGetter<V> = keyof V | ((v: V) => FilterValue);

export type FilterValue = string[] | string | undefined | number[];
/**
 * Builds a function that filters an array of objects based on the in memory
 * filter configuration.
 *
 * @param config The filter config to buld the filter function from
 * @returns The filter function
 */
export function getMemoryFilterFunction<EntryType, Filters extends FilterConfig<EntryType>>(
  config: FilterConfig<EntryType>
): (data: EntryType[], filterValues: Record<keyof Filters, FilterValue>) => EntryType[] {
  return (data: EntryType[], filterValues: Record<keyof Filters, FilterValue>): EntryType[] => {
    if (!data) {
      return [];
    }

    return data.filter((value) =>
      Object.keys(config).every((key) => {
        const { memoryFilterProps } = config[key];
        const { filterType, getter } = memoryFilterProps;
        const filterValue = filterValues[key as keyof Filters];
        const filterFunctionGetter = filterFunctions[filterType];
        const filterFunction = filterFunctionGetter(getter, filterValue);

        return filterFunction(value);
      })
    );
  };
}

const filterFunctions = {
  [InMemoryFilterType.Inclusion]: getInclusionFilter,
  [InMemoryFilterType.UnionInclusion]: getUnionInclusionFilter,
  [InMemoryFilterType.DateRange]: getDateInclusionFilter,
  [InMemoryFilterType.String]: getStringInclusionFilter,
};

function getInclusionFilter<V>(getter: ValueGetter<V>, values: FilterValue) {
  const valuesToCheck = flatMap([values]).filter((v) => !isNullOrUndefined(v));

  return (value: V) => !valuesToCheck?.length || valuesToCheck.includes(getValue(value, getter));
}

function getUnionInclusionFilter<V>(getter: ValueGetter<V>, values: FilterValue) {
  return (value: V) => {
    const arrayValue = getValue(value, getter);

    if (!isArray(values)) {
      throw new Error("UnionInclusion filter must have an array of values");
    }

    // If the value is not an array, we can't check if it includes any of the search values,
    // so we can omit it from the results.
    if (!isArray(arrayValue) && values?.length) {
      return false;
    }

    if (!isArray(arrayValue) || !values?.length) {
      return true;
    }

    return arrayValue?.some((v: string | number) => (values as unknown[]).includes(v));
  };
}

function getDateInclusionFilter<V>(getter: ValueGetter<V>, range: FilterValue) {
  return (value: V) => {
    if (!range || !range[0] || !range[1]) {
      return true;
    }

    if (!isString(range[0]) || !isString(range[1])) {
      throw new Error("Date filters must be strings");
    }

    const currentDate = new Date(getValue(value, getter) as string);
    const startDate = new Date(range[0]);
    const endDate = new Date(range[1]);
    return currentDate >= startDate && currentDate <= endDate;
  };
}

function getStringInclusionFilter<V>(getter: ValueGetter<V>, search: FilterValue) {
  const searchValue = Array.isArray(search) ? search[0] : search;

  return (value: V) =>
    !search ||
    !search.length ||
    String(getValue(value, getter) ?? "")
      .toLowerCase()
      .includes(String(searchValue ?? "").toLowerCase());
}

function getValue<V>(v: V, getter: ValueGetter<V>): FilterValue {
  return typeof getter === "function" ? getter(v) : get(v, getter);
}
