import React, { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { Button, Modal, ModalContent, ModalProps, Text } from "@ds-ui/molecules";
import { ModalFooter } from "@ds-ui/molecules/Modal/ModalFooter";
import {
  DialogProps,
  OpenDialogOptions,
  UseDialogsOptions,
  useDialogs as useGenericDialogs,
} from "./internal/useDialogs";

export { DialogsProvider, isComponentUnmountedResult } from "./internal/useDialogs";

export function useDialogs(options?: UseDialogsOptions) {
  const { open, close: genericClose } = useGenericDialogs(options);

  /** Maps returned promises to those tracked by the dialogs mechanism */
  const promiseMapRef = useRef(new Map());

  const confirm = useCallback(
    (
      message: ReactNode,
      options: ConfirmOptions & Omit<OpenDialogOptions<ConfirmDialogResult>, "onUnmount"> = {}
    ) => {
      const trackedPromise = open(ConfirmDialog, {
        children: message,
        onUnmount: (closeDialog) => {
          return closeDialog(
            options.closeBehavior === "abort" ? new ConfirmationAbortedError() : false
          );
        },
        ...options,
      }) as Promise<ConfirmDialogResult>; // TODO - find a way to disable symbol as return type when providing a custom onUnmount

      const result = trackedPromise
        .then((result) => {
          if (result instanceof ConfirmationAbortedError) {
            throw result;
          }
          return result;
        })
        .finally(() => {
          promiseMapRef.current.delete(result);
        });

      promiseMapRef.current.set(result, trackedPromise);

      return result;
    },
    [open]
  );

  const close = useCallback(
    function close<R>(dialog: Promise<R>, result: boolean) {
      return genericClose(promiseMapRef.current.get(dialog), result);
    },
    [genericClose]
  );

  return useMemo(() => ({ open, confirm, close }), [open, close, confirm]);
}

export interface ConfirmOptions {
  title?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  ModalProps?: Partial<Omit<ModalProps, "open" | "children" | "title" | "onClose">>;
  closeBehavior?: "cancel" | "abort";
}

export type ConfirmDialogResult = boolean | ConfirmationAbortedError;
export type ConfirmDialogProps = DialogProps<ConfirmDialogResult> &
  ConfirmOptions & {
    children: ReactNode;
  };

function ConfirmDialog({
  open,
  children,
  title,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  ModalProps: modalProps,
  closeBehavior,
  onClose,
}: ConfirmDialogProps) {
  const text = typeof children === "string" ? <Text>{children}</Text> : children;

  const [loadingInitiator, setLoadingInitiator] = useState<
    "confirmButton" | "cancelButton" | "modal" | null
  >(null);

  // Click on confirm, cancel, modal close button or backdrop
  async function onUserFeedbackReceive(
    result: Parameters<typeof onClose>[0],
    initiator: NonNullable<typeof loadingInitiator>
  ) {
    try {
      setLoadingInitiator(initiator);
      await onClose(result);
    } finally {
      setLoadingInitiator(null);
    }
  }

  return (
    <Modal
      open={open}
      title={title}
      {...modalProps}
      {...(closeBehavior &&
        !loadingInitiator && {
          onClose: () => {
            onUserFeedbackReceive(
              closeBehavior === "cancel" ? false : new ConfirmationAbortedError(),
              "modal"
            );
          },
        })}
    >
      <ModalContent>
        {text}

        <ModalFooter>
          <Button
            testId="cancel-button"
            className="w-full"
            weight="secondary"
            loading={loadingInitiator === "cancelButton"}
            disabled={Boolean(loadingInitiator)}
            onClick={() => onUserFeedbackReceive(false, "cancelButton")}
          >
            {cancelButtonText}
          </Button>
          <Button
            testId="confirm-button"
            className="w-full"
            loading={loadingInitiator === "confirmButton"}
            disabled={Boolean(loadingInitiator)}
            onClick={() => onUserFeedbackReceive(true, "confirmButton")}
          >
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export class ConfirmationAbortedError extends Error {}
