import React from "react";
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import { OverridableComponent } from "@mui/material/OverridableComponent";

// Re-export everything from @mui/material/TextField
export * from "@mui/material/TextField";

// Define custom props
export type TextFieldProps = Omit<MuiTextFieldProps, "variant">;

// Define the type map for the overridable component
interface TextFieldTypeMap {
  props: TextFieldProps;
  defaultComponent: "input";
}

const TextField = ((props: TextFieldProps) => {
  return <MuiTextField {...props} />;
}) as OverridableComponent<TextFieldTypeMap>;

// Override only the TextField component
export { TextField };

declare module "@mui/material/TextField" {
  interface TextFieldVariantOverrides {
    filled: false;
    standard: false;
  }
}
