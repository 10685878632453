import React, { FunctionComponent } from "react";
import { FieldRenderProps } from "react-final-form";
import { InputAdornment, TextField } from "@mui/material";
import Person from "@mui/icons-material/Person";
import { mapProps } from "ui";

export const EmailField: FunctionComponent<React.PropsWithChildren<FieldRenderProps>> = (props) => (
  <TextField
    {...mapProps(props)}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Person color="action" />
        </InputAdornment>
      ),
    }}
  />
);

export const EmailFieldNoIcon: FunctionComponent<React.PropsWithChildren<FieldRenderProps>> = (
  props
) => <TextField {...mapProps(props)} />;
