import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { focusedInteractiveOutlineStyles } from "@ds-ui/styles";
import { Chip, ChipProps } from "@mui/material";
import { Text, TextProps } from "./Text";

export type TagProps = {
  label: string;
  textProps?: TextProps;
  numerical?: boolean;
} & Omit<ChipProps, "size"> &
  VariantProps<typeof tagStyles>;

// TODO: add severity prop
const tagStyles = cva(
  "h-auto rounded-lg bg-background-base2 border border-solid border-outlines-1",
  {
    variants: {
      clickable: {
        true: focusedInteractiveOutlineStyles,
      },
      noWrap: {
        true: "truncate",
      },
      size: {
        sm: "py-xxs px-m h-[20px]",
        md: "py-xs px-m h-[28px]",
      },
    },

    defaultVariants: {
      clickable: false,
      size: "md",
    },
  }
);

export const Tag = ({
  label,
  textProps,
  onClick,
  className,
  numerical,
  size = "sm",
  noWrap = true,
  ...rest
}: TagProps) => {
  const clickable = Boolean(onClick);

  return (
    <Chip
      label={
        <Text variant={numerical ? "subtextNumeric" : "subtextText"} {...textProps}>
          {label}
        </Text>
      }
      title={label}
      sx={{
        ".MuiChip-root": {
          height: "auto",
        },
        ".MuiChip-label": {
          padding: 0,
          display: "inline-block",
          maxWidth: "100%",
        },
      }}
      onClick={onClick}
      className={twMerge(
        tagStyles({
          className,
          clickable,
          size,
          noWrap,
        })
      )}
      {...rest}
    />
  );
};
