import { ThemeOptions, assertNonNullish, ButtonProps } from "@ds-proxy";
import { darken, Theme } from "@mui/material/styles";
import { NamedTheme } from "@ds-proxy/theme";
import { PaletteColor } from "@mui/material/styles/createPalette";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { pxToRem } from "./utils";

// TODO Maybe clean this file by moving the button style utilities to a separate file
const btnColors = ["primary", "secondary", "success", "error", "info", "warning"] satisfies Array<
  ButtonProps["color"]
>;
const getSubtleButtonStyles = (colorSet: PaletteColor, colorSetName: string, theme: string) => {
  return {
    "backgroundColor": colorSet.ultralight,
    "color": colorSet.main,

    "&:hover": {
      backgroundColor: darken(
        assertNonNullish(
          colorSet.ultralight,
          `Can't find ${colorSetName}/ultralight on theme ${theme}`
        ),
        0.025
      ),
    },

    "&:active": {
      backgroundColor: darken(
        assertNonNullish(
          colorSet.ultralight,
          `Can't find ${colorSetName}/ultralight on theme ${theme}`
        ),
        0.05
      ),
    },
  };
};
const getSubtleButtonGroupStyles = (colorSet: PaletteColor) => {
  return {
    ":not(.MuiButtonGroup-vertical)": {
      "> *:not(:last-child)": {
        borderRight: `1px solid ${colorSet.light}`,
      },
    },

    "&.MuiButtonGroup-vertical": {
      "> *:not(:last-child)": {
        borderBottom: `1px solid ${colorSet.light}`,
      },
    },
  };
};

const getSubtleButtonVariants = (theme: NamedTheme): ComponentsVariants<Theme>["MuiButton"] => {
  return btnColors.map((color) => {
    return {
      props: { variant: "subtle", color },
      style: getSubtleButtonStyles(theme.palette[color], color, theme.name),
    };
  });
};

const getSubtleButtonGroupVariants = (
  theme: NamedTheme
): ComponentsVariants<Theme>["MuiButtonGroup"] => {
  return btnColors.map((color) => {
    return {
      props: { variant: "subtle", color },
      style: getSubtleButtonGroupStyles(theme.palette[color]),
    };
  });
};

export const getThemeComponents = (theme: NamedTheme): ThemeOptions["components"] => {
  return {
    /* **************************************
     ************ 🚀  Various  ************
     ************************************** */
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "currentColor",
        },
      },
    },

    /* **************************************
     ************ 🚀  Chips  ************
     ************************************** */
    MuiChip: {
      styleOverrides: {
        root: {
          "height": 26,
          "maxWidth": "100%",

          " .MuiInputBase-root": {
            backgroundColor: "transparent",
          },
        },
        icon: {
          color: "unset",
          marginLeft: 10,
          fontSize: 20,
        },
        deleteIcon: {
          margin: `0 10px 0 -2px`,
          fontSize: 20,
        },
        label: {
          display: "block",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        sizeMedium: {
          "height": "28px",
          "padding": "4px 0",

          ".MuiChip-label": {
            padding: "0 12px",
          },
        },
        sizeSmall: {
          "height": "24px",
          "padding": "2px 0",

          ".MuiChip-label": {
            padding: "0 8px",
          },

          ".MuiChip-deleteIconSmall": {
            fontSize: "16px",
          },
        },
      },
    },

    /* **************************************
     ************ 🚀  Dialogs  ************
     ************************************** */
    MuiBackdrop: {
      styleOverrides: {
        root: {},
        invisible: {},
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "borderRadius": "var(--border-radius-lg)",

          ".MuiDialogTitle-root+.MuiDialogContent-root": {
            paddingTop: "var(--spacing-l)",
          },
          ".MuiDialogContent-root:has(+ .MuiDialogActions-root)": {
            paddingBottom: "var(--spacing-l)",
          },
        },
        paper: {
          borderRadius: "var(--border-radius-lg)",
          backgroundColor: "var(--background-base1)",
          backgroundImage: "unset",
        },
        // TODO: We need max widths for the rest of the sizes
        paperWidthXs: {
          maxWidth: 430,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "var(--spacing-l)  var(--spacing-3xl)",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `var(--spacing-3xl)`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "var(--spacing-l)  var(--spacing-3xl)",
        },
      },
    },

    /* **************************************
     ************ 🚀  Tabs  ************
     ************************************** */
    MuiTabs: {
      styleOverrides: {},
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:not(.Mui-selected)": {
            opacity: 0.7,
          },
        },
      },
    },

    /* **************************************
     ************ 🚀  Menu  ************
     ************************************** */
    MuiMenu: {
      styleOverrides: {
        root: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible, &:hover": {
            backgroundColor: "var(--background-active)",
          },
        },
      },
    },

    /* **************************************
     ************ 🚀  Lists  ************
     ************************************** */
    MuiList: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },
        },
      },
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {},
        padding: {
          padding: "var(--spacing-m) var(--spacing-l)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "height": "100%",
          "&:not(.MuiListItemButton-dense)": {
            padding: "var(--spacing-m) var(--spacing-l)",
          },

          "&.Mui-focusVisible, &:hover": {
            backgroundColor: "var(--background-active)",
          },
        },
        dense: {
          padding: "var(--spacing-s) var(--spacing-l)",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListSubheader: {
      // TODO: What's up with this subheader?
      //   styleOverrides: {
      //     root: {
      //       // ...tabTableLabelFontStyle,
      //       // lineHeight: "40px",
      //     },
      //   },
    },
    MuiListItemText: {},

    /* **************************************
     ************ 🚀  TODO: Tables  ************
     ************************************** */
    MuiTableCell: {
      styleOverrides: {
        root: {
          // ...body1FontStyle,
          borderBottom: `1px solid var(--outlines-1)`,
        },
        head: {
          // ...tabTableLabelFontStyle,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: "var(--text-secondary)",
          overflow: "none",
        },
        toolbar: {
          minHeight: 56,
        },
        selectIcon: {
          top: "4px",
          right: "-4px",
        },
        actions: {
          display: "flex",
        },
      },
    },

    /* **************************************
     ************ 🚀  Accordion  ************
     ************************************** */
    MuiAccordion: {
      styleOverrides: {
        root: {
          "boxShadow": "none",
          "backgroundColor": "var(--background-base3)",
          "borderRadius": "var(--border-radius-sm, 4px)",
          "border": "var(--border-width-small, 1px) solid var(--outlines-1)",

          "&.Mui-expanded": {
            margin: 0,
          },

          ":before": {
            display: "none",
          },

          "+.MuiAccordion-root": {
            marginTop: "var(--spacing-s, 8px)",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            "minHeight": "48px",
            "backgroundColor": "var(--background-idle, #F1F5F9)",
            "borderBottom": "var(--border-width-small, 1px) solid var(--outlines-1)",

            " .MuiAccordionSummary-content": {
              margin: "0",
            },
          },
        },
        expandIconWrapper: {
          color: "var(--interactive-positive)",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          background: "var(--background-base3, #F8FAFC)",
          padding: "var(--spacing-s, 8px) var(--spacing-m, 12px)",
          borderRadius: "var(--border-radius-sm, 4px)",
          margin: 0,
        },
      },
    },
    MuiAccordionActions: {},

    /* **************************************
     ************ 🚀  Buttons  ************
     ************************************** */
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "medium",
        variant: "text",
      },
      styleOverrides: {
        root: {
          borderRadius: "var(--border-radius-xs)",
          lineHeight: "1em",
        },

        /* **************************************
         *** Sizes  ***
         ************************************** */
        sizeLarge: {
          padding: "var(--spacing-s) var(--spacing-m)",
          fontSize: pxToRem(16),
          height: "44px",
        },
        sizeMedium: {
          padding: "var(--spacing-xs) var(--spacing-s)",
          fontSize: pxToRem(14),
          height: "36px",
        },
        sizeSmall: {
          padding: "var(--spacing-xs) var(--spacing-s)",
          fontSize: pxToRem(12),
          height: "30px",
        },
      },
      variants: getSubtleButtonVariants(theme),
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          display: "flex",
        },
        // @ts-expect-error - TODO: Mui Correctly applies the class, find a TS valid way to fix this after upgrading to v6
        sizeExtraSmall: {
          padding: "var(--spacing-xxs)",
          fontSize: "var(--font-size-s)",
        },
        sizeSmall: {
          padding: "var(--spacing-xs)",
          fontSize: "var(--font-size-s)",
        },
        sizeMedium: {
          padding: "var(--spacing-xs)",
          fontSize: "var(--font-size-m)",
        },
        sizeLarge: {
          padding: "var(--spacing-s)",
          fontSize: "var(--font-size-l)",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: "8px",
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        size: "medium",
      },
      styleOverrides: {},
      variants: getSubtleButtonGroupVariants(theme),
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
      defaultProps: {
        underline: "hover",
        color: "primary",
      },
    },

    /* **************************************
     ************ 🚀  INPUTS  ************
     ************************************** */
    MuiCheckbox: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "primary",
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: "0",
          },
          "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root": {
            paddingRight: "60px",
          },
        },
        inputRoot: {
          "padding": "12px",

          "&.MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            padding: "2px 12px",
          },
        },
        endAdornment: {
          display: "flex",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: "var(--outlines-1)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // "height": "44px", // we need to set this to 44px because the border is represented by the fieldset, so 44 is the 42 actual input + 2px from the border
          "padding": "0 12px",
          "background": "var(--background-base1, #FCFCFC)",
          "color": "var(--text-primary, #020617)",

          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "var(--border-width-medium, 2px) solid var(--outlines-focus, #60A5FA)",
          },

          "&.Mui-focused": {
            "& fieldset": {
              border: "var(--border-width-medium, 2px) solid var(--outlines-focus, #60A5FA)",
            },
          },

          "&.Mui-error": {
            borderWidth: "var(--border-width-medium, 2px)",
          },
        },

        input: {
          "padding": "12px 0",
          "height": "unset",
          "lineHeight": "1.25em",
          "boxSizing": "border-box",
          // This is tied to MuiTextField LabelProps shrink, That needs to be true all the time
          "::placeholder": {
            color: "var(--text-subtle, #A1A1AA)",
            opacity: "1 !important",
          },
        },
        // Multiline
        multiline: {
          height: "unset",
          minHeight: "44px",
        },
        inputMultiline: {
          "line-height": "1.4375em",
        },
        // Size small
        sizeSmall: {
          height: "36px",
          padding: "0 8px",
        },
        inputSizeSmall: {
          padding: "8px 0",
          height: "100%",
        },
        // Border
        notchedOutline: {
          "top": 0,
          "border": "var(--border-width-small, 1px) solid var(--outlines-2, #E2E8F0)",
          "borderRadius": "2px",
          " legend": {
            display: "none",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: "relative",
          transform: "none",
          color: "var(--text-subtle, #A1A1AA)",
          fontSize: "var(--font-size-s, 12px)",
          marginBottom: 0,
        },
        outlined: {
          position: "relative",
          transform: "none",
          color: "var(--text-subtle)",
          fontSize: "var(--font-size-s, 12px)",
          marginBottom: "var(--spacing-s, 8px)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          color: "var(--text-subtle)",
          fontSize: "var(--font-size-s)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: "var(--spacing-s, 8px)",
          lineHeight: 1,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        InputLabelProps: { shrink: true },
      },
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
  };
};
