import React, { useState, useMemo } from "react";
import { Checkbox } from "ds-ui/molecules/Checkbox";
import { Icon } from "ds-ui/icons";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { colors, spacing } from "@ds-ui/generated/tailwind-variables";

type NestedCheckboxes<T> = {
  value: T[];
  label?: string;
  state: "checked" | "unchecked" | "indeterminate";
  items?: Array<NestedCheckboxes<T>>;
};

type NestedCheckboxProps<T> = NestedCheckboxes<T> & {
  onChange: (value: T[]) => void;
};

export function NestedCheckbox<T>({
  value,
  label,
  state,
  onChange,
  items,
}: NestedCheckboxProps<T>) {
  const [expanded, setExpanded] = useState(false);

  const { checked, total } = useMemo(() => {
    const countChecked = (
      items?: Array<NestedCheckboxes<T>>
    ): { checked: number; total: number } => {
      let checked = 0;
      let total = 0;

      items?.forEach((item) => {
        if (item.items) {
          const { checked: checkedCount, total: totalCount } = countChecked(item.items);
          checked += checkedCount;
          total += totalCount;
        } else {
          if (item.state === "checked") {
            checked++;
          }
          total++;
        }
      });

      return { checked, total };
    };

    return countChecked(items);
  }, [items]);

  const text =
    (label || (value?.[0] as string)) +
    (!items?.length ? "" : checked !== total ? ` (${checked}/${total})` : ` (${total})`);

  return (
    <>
      <ListItemButton
        sx={{
          "&.MuiListItemButton-root": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <ListItemIcon>
          <Checkbox
            onChange={() => onChange(value)}
            checked={state === "checked"}
            indeterminate={state === "indeterminate"}
            sx={{
              "&.MuiButtonBase-root": {
                padding: 0,
              },
            }}
          />
        </ListItemIcon>
        <ListItemText
          onClick={() => setExpanded(!expanded)}
          primary={text}
          sx={{
            "&.MuiListItemText-root": {
              flex: "none",
            },
            "&.MuiListItemText-root .MuiTypography-root": {
              color: state === "unchecked" ? colors.text.secondary : colors.text.primary,
            },
          }}
        />
        {items && items?.length > 0 && (
          <ListItemIcon sx={{ "&.MuiListItemIcon-root": { paddingLeft: spacing.m } }}>
            <Icon
              size="s"
              className={`${state === "unchecked" ? "text-interactive-positive" : "text-primary"} `}
              name={expanded ? "ExpandMoreOutlined" : "ChevronRightOutlined"}
            />
          </ListItemIcon>
        )}
      </ListItemButton>
      {expanded && (
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ paddingLeft: spacing.l }}>
          <List sx={{ padding: 0 }}>
            {items?.map((item) => (
              <NestedCheckbox<T>
                key={label || (value[0] as string)}
                {...item}
                onChange={onChange}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
